<template>
  <div>
    <v-card class="mt-4">
      <v-card-text style="display: contents">
        <v-expansion-panels>
          <v-expansion-panel>
            <v-expansion-panel-header>
              {{ $localize('RequestProcessingTaffler') }}
            </v-expansion-panel-header>
            <v-expansion-panel-content>
              <TafflerModel :items="itemsTaffler" :root="root" @openDialog="openDialog"/>
            </v-expansion-panel-content>
          </v-expansion-panel>
          <v-expansion-panel>
            <v-expansion-panel-header>
              {{ $localize('RequestProcessingChesser') }}
            </v-expansion-panel-header>
            <v-expansion-panel-content>
              <ChesserModel :items="itemsChesser" :root="root" @openDialog="openDialog"/>
            </v-expansion-panel-content>
          </v-expansion-panel>
          <v-expansion-panel>
            <v-expansion-panel-header>
              {{ $localize('RequestProcessingIgea') }}
            </v-expansion-panel-header>
            <v-expansion-panel-content>
              <IgeaModel :items="itemsIgea" :root="root" @openDialog="openDialog"/>
            </v-expansion-panel-content>
          </v-expansion-panel>
        </v-expansion-panels>
      </v-card-text>
    </v-card>
    <v-dialog v-model="dialog" max-width="720" scrollable>
      <v-card>
        <v-tabs
            ref="tabs"
            v-model="tab"
            style="position:sticky; top: 0; z-index: 1"
            class="mt-0 pt-0"
            show-arrows
        >
          <v-tab
              v-for="item in types"
              :key="item.index"
          >
            {{ $localize(item) }}
          </v-tab>
        </v-tabs>
        <v-card-text style="height: 400px">
          <v-tabs-items v-model="tab">
            <v-tab-item v-for="type in types"
                        :key="type"
            >
              <DictTreeviewForModels :root="getRoot"
                                     @treeClicked="treeClicked"
              />
            </v-tab-item>
          </v-tabs-items>
        </v-card-text>
        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn text @click="closeDialog">{{ $localize('cancel') }}</v-btn>
          <v-btn
              color="success"
              :disabled="!isSelectedDict"
              @click="ok">OK</v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
  </div>
</template>

<script>
import TafflerModel from "./models/TafflerModel";
import ChesserModel from "./models/ChesserModel";
import IgeaModel from "./models/IgeaModel";
import DictTreeviewForModels from "./DictTreeviewForModels";

export default {
  name: "ModelsSettings",
  components: {IgeaModel, TafflerModel, ChesserModel, DictTreeviewForModels},
  data : () => ({
    tab: undefined,
    types: [],
    root: {
      uid: 0,
      title: "root",
      items: [],
    },
    selectedDict: undefined,
    editingIdx: undefined,
    editingType: undefined,

    itemsTaffler: [],
    itemsChesser: [],
    itemsIgea: [],
    dialog: false,
  }),

  computed: {
    getRoot() {
      let tabRoot = this.$lodash.clone(this.root)
      tabRoot.items = this.root.items.filter(el => {
        return el.type === this.types[this.tab]
      })
      return tabRoot
    },

    isSelectedDict(){
      return !(!this.selectedDict || this.selectedDict.length === 0)
    },
  },

  mounted(){
    this.init()
    this.initTypes()
  },

  methods:{
    async init(){
      this.$appUtils.setOverlay(true)
      const dictRes = await this.$fetch.get('api/dictionary/get-list')
      this.$appUtils.setOverlay(false)
      if(dictRes) {
        this.sortDictionaries(dictRes);
        this.root.items = dictRes;
      }
      this.$appUtils.setOverlay(true)
      const resTaffler = await this.$fetch.get('api/settings/taffler/get-list')
      this.$appUtils.setOverlay(false)
      if(resTaffler) {
        this.sortModels(resTaffler)
        this.itemsTaffler = resTaffler
      }
      this.$appUtils.setOverlay(true)
      const resChesser = await this.$fetch.get('api/settings/chesser/get-list')
      this.$appUtils.setOverlay(false)
      if(resChesser) {
        this.sortModels(resChesser)
        this.itemsChesser = resChesser
      }
      this.$appUtils.setOverlay(true)
      const resIgea = await this.$fetch.get('api/settings/igea/get-list')
      this.$appUtils.setOverlay(false)
      if(resIgea) {
        this.sortModels(resIgea)
        this.itemsIgea = resIgea
      }
    },

    async initTypes(){
      this.$appUtils.setOverlay(true)
      this.types = await this.$fetch.get('api/dictionary/get-types')
      this.$appUtils.setOverlay(false)
    },

    treeClicked(item){
      this.selectedDict = item
    },

    openDialog(type, idx){
      this.editingType = type
      this.editingIdx = idx
      this.dialog = true
    },

    closeDialog(){
      this.dialog = false
      this.editingType = undefined
      this.editingIdx = undefined
      this.selectedDict = undefined
    },

    ok(){
      switch (this.editingType){
        case 'TAFFLER' : this.itemsTaffler[this.editingIdx.idx].model[this.editingIdx.key] = this.selectedDict[0]; break;
        case 'CHESSER' : this.itemsChesser[this.editingIdx.idx].model[this.editingIdx.key] = this.selectedDict[0]; break;
        case 'IGEA' : this.itemsIgea[this.editingIdx.idx].model[this.editingIdx.key] = this.selectedDict[0]; break;
      }
      this.closeDialog()
    },

    sortDictionaries(items){
      if(items && items.length > 0){
        items.sort((a,b) => (a.title > b.title) ? 1 : ((b.title > a.title) ? -1 : 0))
        items.forEach(el => {
          if(el.items) this.sortDictionaries(el.items)
        })
      }
    },

    sortModels(items){
      if(items && items.length > 0){
        items.sort((a,b) => (a.key > b.key) ? 1 : ((b.key > a.key) ? -1 : 0))
      }
    },
  }
}
</script>
