<template>
  <div>
    <v-treeview
        :active.sync="active"
        :items="root.items"
        item-children="items"
        item-text="title"
        item-key="uid"
        color="success"
        transition
        activatable
        @update:active="click"
    >
      <template v-slot:prepend="{ item }">
        <v-icon v-if="!item.items">
          mdi-book-open
        </v-icon>
      </template>
    </v-treeview>
  </div>
</template>

<script>
export default{
  name: "DictTreeviewForModels",
  props: ['root'],
  data : () => ({
    active: [],
    item: {
      parentUid: undefined,
      type: undefined,
      title: undefined,
      uid: undefined,
    },
    test: undefined,
  }),

  methods:{
    click(item){
      this.$emit('treeClicked', item)
    }
  }
}
</script>