<template>
  <div class="view-wrapper">
    <v-toolbar dense class="toolbar-with-tabs elevation-0" style="flex: none;">
      <v-tabs ref="tabs"
              v-model="tab"
              style="position:sticky; top: 0; z-index: 1"
              class="mt-0 pt-0  mb-2"
              color="success"
      >
        <v-tab>
          {{ $localize('payment_limit') }}
        </v-tab>
        <v-tab>
          {{ $localize('models_settings') }}
        </v-tab>
      </v-tabs>
    </v-toolbar>
    <div style="height: 100%">
      <v-tabs-items v-model="tab" class="tab-table-1" style="height: 100%; overflow: auto">
        <v-tab-item>
          <v-card-title class="justify-center">
            {{ $localize('payment_limit') }}
          </v-card-title>
          <v-divider/>
          <v-card-actions class="justify-center">
            <div class="two-columns-grid">
<!--              <v-text-field v-model="item.sum"-->
<!--                            :label="$localize('limit')"-->
<!--                            type="number"-->
<!--                            class="input-number-without-spin-button pt-7"-->
<!--                            outlined-->
<!--                            dense-->
<!--                            clearable-->
<!--              >-->
<!--              </v-text-field>-->
              <vuetify-money v-model="item.sum"
                             :rules="[rules.required]"
                             :label="$localize('limit')"
                             :options="{prefix: 'KZT', precision: 0}"
                             outlined
                             clearable
                             dense
                             class="mt-6"
              ></vuetify-money>
              <v-btn color="success"
                     class="ml-2"
                     :disabled="!item.sum"
                     @click="setLimit"
              >
                <span class="pl-2 pr-2">{{$localize('save')}}</span>
              </v-btn>
            </div>
          </v-card-actions>
        </v-tab-item>
        <v-tab-item eager style="width: 100%">
          <Models/>
        </v-tab-item>
      </v-tabs-items>
    </div>
  </div>
</template>

<script>
import Models from "./components/Models";

export default {
  name: "Settings",
  components: {Models},
  data : () => ({
    rules: {
      required: value => !!value || this.$localize('mandatory_field'),
    },
    tab: undefined,
    item: {
      sum: undefined,
      type: 'REQUEST'
    },
  }),

  methods:{
    async setLimit(){
      const res = await this.$fetch.post('api/settings/payment/save', this.item)
      if(res && res.status === 'ACCESS') this.$store.dispatch('setError',{ show: true, type: 'success', message: this.$localize('success')})
      else this.$store.dispatch('setError',{ show: true, type: 'error', message: this.$localize('data_sending_error')})
      this.item.sum = undefined
    }
  }
}
</script>




