<template>
  <div>
    <v-simple-table fixed-header
                    dense
                    mobile-breakpoint="0"
                    style="height: auto !important;"
                    class="table-with-borders"
    >
      <template v-slot:default>
        <thead>
        <tr>
          <th class="text-center">
            {{  $localize('indicator_name') }}
          </th>
          <th>Выражение</th>
        </tr>
        </thead>
        <tbody>
        <template>
          <tr>
            <td>Активы</td>
            <td>
              <template>
                <v-container>
                  <v-row class="align-center justify-center">
                    <v-btn text @click="openDialog({idx: 0, key: 'termDictUid'})">{{ getTitle({idx: 0, key: 'termDictUid'}) }}</v-btn>
                  </v-row>
                </v-container>
              </template>
            </td>
          </tr>
          <tr>
            <td>Оборотный капитал</td>
            <td>
              <template>
                <v-container>
                  <v-row class="align-center justify-center">
                    <v-btn text @click="openDialog({idx: 1, key: 'termDictUid'})">{{ getTitle({idx: 1, key: 'termDictUid'}) }}</v-btn>
                    <span class="title pa-2">{{"   -  "}}</span>
                    <v-btn text @click="openDialog({idx: 1, key: 'minusDictUid'})">{{ getTitle({idx: 1, key: 'minusDictUid'}) }}</v-btn>
                  </v-row>
                </v-container>
              </template>
            </td>
          </tr>
          <tr>
            <td>Чистая прибыль (убыток)</td>
            <td>
              <template>
                <v-container>
                  <v-row class="align-center justify-center">
                    <v-btn text @click="openDialog({idx: 2, key: 'termDictUid'})">{{ getTitle({idx: 2, key: 'termDictUid'}) }}</v-btn>
                  </v-row>
                </v-container>
              </template>
            </td>
          </tr>
          <tr>
            <td>Балансовая стоимость собственного капитала</td>
            <td>
              <template>
                <v-container>
                  <v-row class="align-center justify-center">
                    <v-btn text @click="openDialog({idx: 3, key: 'termDictUid'})">{{ getTitle({idx: 3, key: 'termDictUid'}) }}</v-btn>
                  </v-row>
                </v-container>
              </template>
            </td>
          </tr>
          <tr>
            <td>Выручка</td>
            <td>
              <template>
                <v-container>
                  <v-row class="align-center justify-center">
                    <v-btn text @click="openDialog({idx: 4, key: 'termDictUid'})">{{ getTitle({idx: 4, key: 'termDictUid'}) }}</v-btn>
                  </v-row>
                </v-container>
              </template>
            </td>
          </tr>
          <tr>
            <td>Суммарные расходы</td>
            <td>
              <template>
                <v-container>
                  <v-row class="align-center justify-center">
                    <v-btn text @click="openDialog({idx: 5, key: 'termDictUid'})">{{ getTitle({idx: 5, key: 'termDictUid'}) }}</v-btn>
                    <span class="title pa-2">{{"   +   "}}</span>
                    <v-btn text @click="openDialog({idx: 5, key: 'term1DictUid'})">{{ getTitle({idx: 5, key: 'term1DictUid'}) }}</v-btn>
                    <span class="title pa-2">{{"   +   "}}</span>
                    <v-btn text @click="openDialog({idx: 5, key: 'term2DictUid'})">{{ getTitle({idx: 5, key: 'term2DictUid'}) }}</v-btn>
                    <span class="title pa-2">{{"   +   "}}</span>
                    <v-btn text @click="openDialog({idx: 5, key: 'term3DictUid'})">{{ getTitle({idx: 5, key: 'term3DictUid'}) }}</v-btn>
                    <span class="title pa-2">{{"   +   "}}</span>
                    <v-btn text @click="openDialog({idx: 5, key: 'term4DictUid'})">{{ getTitle({idx: 5, key: 'term4DictUid'}) }}</v-btn>
                  </v-row>
                </v-container>
              </template>
            </td>
          </tr>
        </template>
        </tbody>
      </template>
    </v-simple-table>
    <v-row class="justify-center">
      <v-spacer/>
      <v-btn outlined
             class="mt-6 mb-2 mr-4"
             color="success"
             small
             :disabled="!isIgeaFilled"
             @click="save"
      >
        {{$localize('save')}}
      </v-btn>
    </v-row>
  </div>
</template>

<script>
export default{
  name: "IgeaModel",
  props: ['root', 'items'],

  computed: {
    isIgeaFilled(){
      if(!this.items) return false
      else if(!this.items[1].model.termDictUid || !this.items[1].model.minusDictUid) return false
      else if(!this.items[3].model.termDictUid) return false
      else if(!this.items[4].model.termDictUid) return false
      else if(!this.items[5].model.termDictUid
          || !this.items[5].model.term1DictUid
          || !this.items[5].model.term2DictUid
          || !this.items[5].model.term3DictUid
          || !this.items[5].model.term4DictUid) return false
      return true
    }
  },
  methods:{
    openDialog(idx){this.$emit('openDialog', 'IGEA', idx)},

    getTitle(item){
      return this.items[item.idx].model[item.key] ? this.$treeSearch(this.root, this.items[item.idx].model[item.key]).title : this.$localize('choose_the_indicator')
    },

    async save(){
      const resIgea = await this.$fetch.post('api/settings/igea/save', this.items)
      if(resIgea)  this.$store.dispatch('setError',{ show: true, type: 'success', message: this.$localize('success')})
      else  this.$store.dispatch('setError',{ show: true, type: 'error', message: this.$localize('data_sending_error')})
    }
  }
}
</script>
