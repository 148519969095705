<template>
  <div>
    <v-simple-table fixed-header
                    dense
                    mobile-breakpoint="0"
                    style="height: auto !important;"
                    class="table-with-borders"
    >
      <template v-slot:default>
        <thead>
        <tr>
          <th class="text-center">
            {{  $localize('indicator_name') }}
          </th>
          <th>Выражение</th>
        </tr>
        </thead>
        <tbody>
        <template>
          <tr>
            <td>Х1 - Отношение прибыли до уплаты налога к сумме текущих обязательств</td>
            <td>
              <template>
                <v-container>
                  <v-row class="align-center justify-center">
                    <v-btn text @click="openDialog({idx: 0, key: 'divisibleDictUid'})">{{ getTitle({idx: 0, key: 'divisibleDictUid'}) }}</v-btn>
                    <span class="title pa-2">{{"   /   "}}</span>
                    <v-btn text @click="openDialog({idx: 0, key: 'dividerDictUid'})">{{ getTitle({idx: 0, key: 'dividerDictUid'}) }}</v-btn>
                  </v-row>
                </v-container>
              </template>
            </td>
          </tr>
          <tr>
            <td>Х2 - Отношение суммы текущих активов к общей сумме обязательств</td>
            <td>
              <template>
                <v-container>
                  <v-row class="align-center justify-center">
                    <v-btn text @click="openDialog({idx: 1, key: 'divisibleDictUid'})">{{ getTitle({idx: 1, key: 'divisibleDictUid'}) }}</v-btn>
                    <span class="title pa-2">{{"   / (  "}}</span>
                    <v-btn text @click="openDialog({idx: 1, key: 'dividerDictUid'})">{{ getTitle({idx: 1, key: 'dividerDictUid'}) }}</v-btn>
                    <span class="title pa-2">{{"   +   "}}</span>
                    <v-btn text @click="openDialog({idx: 1, key: 'dividerTermDictUid'})">{{ getTitle({idx: 1, key: 'dividerTermDictUid'}) }}</v-btn>
                    <span class="title pa-2">{{"   )"}}</span>
                  </v-row>
                </v-container>
              </template>
            </td>
          </tr>
          <tr>
            <td>Х3 - Отношение суммы текущих обязательств к общей сумме активов</td>
            <td>
              <template>
                <v-container>
                  <v-row class="align-center justify-center">
                    <v-btn text @click="openDialog({idx: 2, key: 'divisibleDictUid'})">{{ getTitle({idx: 2, key: 'divisibleDictUid'}) }}</v-btn>
                    <span class="title pa-2">{{"   /   "}}</span>
                    <v-btn text @click="openDialog({idx: 2, key: 'dividerDictUid'})">{{ getTitle({idx: 2, key: 'dividerDictUid'}) }}</v-btn>
                  </v-row>
                </v-container>
              </template>
            </td>
          </tr>
          <tr>
            <td>Х4 - Отношение выручки к общей сумме активов</td>
            <td>
              <template>
                <v-container>
                  <v-row class="align-center justify-center">
                    <v-btn text @click="openDialog({idx: 3, key: 'divisibleDictUid'})">{{ getTitle({idx: 3, key: 'divisibleDictUid'}) }}</v-btn>
                    <span class="title pa-2">{{"   /   "}}</span>
                    <v-btn text @click="openDialog({idx: 3, key: 'dividerDictUid'})">{{ getTitle({idx: 3, key: 'dividerDictUid'}) }}</v-btn>
                  </v-row>
                </v-container>
              </template>
            </td>
          </tr>
        </template>
        </tbody>
      </template>
    </v-simple-table>
    <v-row class="justify-center">
      <v-spacer/>
      <v-btn outlined
             class="mt-6 mb-2 mr-4"
             color="success"
             small
             :disabled="!isTafflerFormFilled"
             @click="save"
      >
        {{$localize('save')}}
      </v-btn>
    </v-row>
  </div>
</template>

<script>
export default{
  name: "TafflerModel",
  props: ['root', 'items'],

  computed: {
    isTafflerFormFilled(){
      if(!this.items) return false
      else if(!this.items[0].model.divisibleDictUid || !this.items[0].model.dividerDictUid) return false
      else if(!this.items[1].model.divisibleDictUid || !this.items[1].model.dividerDictUid || !this.items[1].model.dividerTermDictUid) return false
      else if(!this.items[2].model.divisibleDictUid) return false
      else if(!this.items[3].model.divisibleDictUid) return false
      return true
    }
  },
  methods:{
    openDialog(idx){this.$emit('openDialog', 'TAFFLER', idx)},

    getTitle(item){
      return this.items[item.idx].model[item.key] ? this.$treeSearch(this.root, this.items[item.idx].model[item.key]).title : this.$localize('choose_the_indicator')
    },

    async save(){
      const resTaffler = await this.$fetch.post('api/settings/taffler/save', this.items)
      if(resTaffler)  this.$store.dispatch('setError',{ show: true, type: 'success', message: this.$localize('success')})
      else this.$store.dispatch('setError',{ show: true, type: 'error', message: this.$localize('data_sending_error')})
    }
  }
}
</script>
